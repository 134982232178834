const menu = [
    {
        "name": "Bio",
        "link": "#bio"
    },
    {
        "name": "Projects",
        "link": "#projects"
    },
    {
        "name": "Contact",
        "link": "#contact"
    },
]

export default menu